import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import Logo from "../stylesheets/assets/logo-negro.svg"

function Header({ siteTitle }) {
  const [menu, setMenu] = useState(false)
  useEffect(() => {
    console.log(menu)
  })
  return (
    <header className="header">
      <div className="nav-bar">
        {/*Container*/}
        <div className="container">
          <div className=" main-nav">
            <div className="four columns">
              <a href="/">
                <img className="logo" alt={siteTitle} src={Logo} />
              </a>
            </div>
            <div className="twelve columns tRight nav-holder">
              <div className="mobile-btn" onClick={() => setMenu(!menu)}>
                <div className={menu ? 'burger-icon change' : 'burger-icon'}>
                  <div className="bar1"></div>
                  <div className="bar2"></div>
                  <div className="bar3"></div>
                </div>
              </div>
              {/* <a href="#" className="mobile-btn" onClick={() => setMenu(!menu)}>
                |||
              </a> */}
              <nav
                className="menu-holder main-nav"
                // style={{ display: "block" }}
                style={{
                  display: menu ? "block" : "",
                }}
              >
                <ul className="menu">
                  <li className="">
                    <Link to="/">Inicio</Link>
                  </li>
                  <li className="">
                    <Link to="/sobre-mi">Sobre mi</Link>
                  </li>
                  <li className="">
                    <Link to="/trabajo">Trabajo</Link>
                  </li>
                  <li className="">
                    <Link to="/contacto">Contactar</Link>
                  </li>
                  {/* <li className="sub">
                  <a href="#">About</a>
                  <ul className="dropDown" style={{ display: "none" }}>
                    <li>
                      <a href="about.html">Layout 1</a>
                    </li>
                    <li>
                      <a href="about_alt.html">Layout 2</a>
                    </li>
                    <li>
                      <a href="clients.html">Clients</a>
                    </li>
                  </ul>
                </li> */}
                </ul>
              </nav>
            </div>
          </div>
        </div>
        {/*End container*/}
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
